import { NextPage } from "next";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { User, UserStatusType } from "@/core/store/redux/auth/types";

import { contextActions } from "@/core/store/redux/context/actions";
import { isAllowedRedirect, POST_LOGIN_CONFIRMED_REDIRECT_COOKIE } from "@/core/utils/redirects";
import { contextSelectors } from "@/core/store/redux/context/selectors";
import { Redirect } from "../generic/navigation/Redirect";

export type WithUserProps = {
  user: User;
};

type WithUserOptions = {
  restoreConfirmedUserLogin?: boolean;
};

type ExcludedProps<P> = Pick<P, Exclude<keyof P, keyof WithUserProps>>;

export function withHasUser<P extends WithUserProps>(
  Component: React.FC<P>,
  options?: WithUserOptions,
): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login/start" />;
    return <Component {...props} />;
  }
  return WithWrapper;
}

export function withOptionalUser<P extends WithUserProps>(
  Component: React.FC<P>,
  options?: WithUserOptions,
): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    return <Component {...props} user={user} />;
  }
  return WithWrapper;
}

export function withHasConfirmedUser<P extends WithUserProps>(
  Component: React.FC<P>,
  options?: WithUserOptions,
): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user)
      return (
        <>
          {options?.restoreConfirmedUserLogin && <SaveUrlForLaterConfirmRedirect />}
          <Redirect to="/auth/login/start" />
        </>
      );
    if (user.status == UserStatusType.PENDING_CONFIRMATION) {
      return <Redirect to="/auth/confirm-email/pending" />;
    }
    return (
      <>
        <RestoreUrlForConfirmRedirect />
        <Component {...props} />
      </>
    );
  }
  return WithWrapper;
}

export function withHasUnConfirmedUser<P extends WithUserProps>(
  Component: React.FC<P>,
  options?: WithUserOptions,
): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login/start" />;
    if (user.status == UserStatusType.ACTIVE) {
      return <Redirect to="/" />;
    }
    return <Component {...props} />;
  }
  return WithWrapper;
}

const RestoreUrlForConfirmRedirect: React.FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const redirect = useSelector((state) =>
    contextSelectors.requestCookieValue(state, POST_LOGIN_CONFIRMED_REDIRECT_COOKIE),
  );
  useEffect(() => {
    if (!redirect) return;
    dispatch(contextActions.deleteCookie(POST_LOGIN_CONFIRMED_REDIRECT_COOKIE));
    if (typeof redirect === "string" && isAllowedRedirect(redirect)) {
      router.push(redirect);
    }
  }, [redirect]);
  return null;
};

const SaveUrlForLaterConfirmRedirect: React.FC = () => {
  const pathname = usePathname();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAllowedRedirect(pathname)) {
      dispatch(contextActions.setCookie({ key: POST_LOGIN_CONFIRMED_REDIRECT_COOKIE, value: pathname, ttl: 60 * 60 }));
    }
  }, [pathname]);
  return null;
};
