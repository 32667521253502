import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RouterSegment, RouterState } from "./types";

const getInitialState = (): RouterState => {
  return {
    url: undefined,
    segment: undefined,
  };
};

export const routerReducerSlice = createSlice({
  name: "router",
  initialState: getInitialState(),
  reducers: {
    setUrl: (state, action: PayloadAction<string | undefined>) => {
      if (state.url != action.payload) {
        state.url = action.payload;
      }
    },
    setSegment: (state, action: PayloadAction<RouterSegment | undefined>) => {
      state.segment = action.payload;
    },
  },
});
