import dynamic from "next/dynamic";

const AppGoogleDynamic = dynamic(() => import("./AppTracking/AppGoogle"), {
  ssr: false,
  loading: () => null,
});

const AppTracking = () => {
  return (
    <>
      <AppGoogleDynamic />
    </>
  );
};

export default AppTracking;
