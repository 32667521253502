export const maskAllKeys = (data: any, mask = "***") => {
  // recursivelly, for any key of type string replace with ***
  for (const key in data) {
    if (typeof data[key] === "string") {
      data[key] = "***";
    } else if (typeof data[key] === "object") {
      maskAllKeys(data[key]);
    }
  }
};
