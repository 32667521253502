const DEFAULT_URL_ORIGIN = "https://example.com";

export const addParamsToUrl = (url: string, params: Record<string, any>): string => {
  // !FIXME - use URL + URLSearchParams
  const searchParams = new URLSearchParams();
  for (const key in params) {
    searchParams.set(key, `${params[key]}`);
  }
  return `${url}?${searchParams.toString()}`;
};

export const getURLParams = (url: string | undefined): URLSearchParams => {
  let fullUrl;
  try {
    fullUrl = new URL(url || "");
  } catch {
    fullUrl = new URL(url || "", DEFAULT_URL_ORIGIN);
  }

  return fullUrl.searchParams;
};

export const getURLParamsDict = (url: string | undefined): Record<string, string> => {
  const searchParams = getURLParams(url);

  const dict: Record<string, string> = {};
  const entries = Array.from(searchParams.entries());
  for (let i = 0; i < entries.length; i++) {
    const [key, value] = entries[i];
    dict[key] = value;
  }

  return dict;
};
