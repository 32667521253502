import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Cookie, CookieKey, Cookies } from "@/core/utils/cookies";

import { ContextState } from "./types";

const getInitialState = (): ContextState => {
  return {
    requestCookies: {},
    responseCookies: {},
  };
};

export const contextReducerSlice = createSlice({
  name: "context",
  initialState: getInitialState(),
  reducers: {
    setInitialCookies: (state, action: PayloadAction<Cookies>) => {
      state.requestCookies = action.payload;
      state.responseCookies = action.payload;
    },
    setCookie: (state, action: PayloadAction<Cookie>) => {
      const cookie = action.payload;
      state.responseCookies[cookie.key] = cookie;
    },
    deleteCookie: (state, action: PayloadAction<CookieKey>) => {
      const key = action.payload;
      delete state.responseCookies[key];
    },
  },
});
