import { usePathname, useSearchParams } from "next/navigation";
import { FC, PropsWithChildren, useEffect } from "react";
import { useDispatch } from "react-redux";

import { routerActions } from "@/core/store/redux/router/actions";

const AppRouting: FC<PropsWithChildren> = () => {
  // !FIXME - Convert this into a middleware or add under router/sagas
  const dispatch = useDispatch();
  const pathname = usePathname();

  useEffect(() => {
    dispatch(routerActions.setUrl(pathname));
  }, [pathname, dispatch]);

  return null;
};

export default AppRouting;
