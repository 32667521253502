import { NextPageContext } from "next/types";
import nookies, { parseCookies, setCookie } from "nookies";

const DEFAULT_COOKIE_EXPIRATION = 30 * 24 * 60 * 60;

export type CookieKey = string;
export type CookieValue = string | number | boolean | undefined;
export type Cookie = {
  key: CookieKey;
  value: string | number | boolean | undefined;
  ttl?: number;
};
export type Cookies = Record<string, Cookie>;

export const hasCookie = (ctx: NextPageContext | undefined, key: string): boolean => {
  return Boolean(getCookie(ctx, key));
};

export const getCookie = (ctx: NextPageContext | undefined, key: string): Cookie | undefined => {
  if (ctx && ctx.req) {
    const cookies = nookies.get(ctx);
    if (cookies[key]) {
      return {
        key,
        value: cookies[key],
      };
    }
  } else {
    const cookies = parseCookies();
    if (cookies[key]) {
      return {
        key,
        value: cookies[key],
      };
    }
  }
};

export const getCookies = (ctx?: NextPageContext | undefined): Cookies => {
  if (ctx && ctx.req) {
    const cookies = nookies.get(ctx);
    const cookieKeys = Object.keys(cookies);
    const requestCookies: Cookies = {};
    cookieKeys.forEach((key) => {
      const cookie = {
        key,
        value: cookies[key],
      };
      requestCookies[key] = cookie;
    });
    return requestCookies;
  } else {
    const cookies = parseCookies();
    const cookieKeys = Object.keys(cookies);
    const requestCookies: Cookies = {};
    cookieKeys.forEach((key) => {
      requestCookies[key] = {
        key,
        value: cookies[key],
      };
    });
    return requestCookies;
  }
};

export const saveCookie = (ctx: NextPageContext | undefined, cookie: Cookie): void => {
  if (!cookie.value) {
    deleteCookie(ctx, cookie.key);
    return;
  }
  const options: any = {
    path: "/",
  };
  if (cookie.ttl !== 0) {
    options.maxAge = cookie.ttl ?? DEFAULT_COOKIE_EXPIRATION;
  }
  if (ctx && ctx.req) {
    nookies.set(ctx, cookie.key, String(cookie.value), options);
  } else {
    setCookie(null, cookie.key, String(cookie.value), options);
  }
};

export const saveCookies = (ctx: NextPageContext | undefined, cookies: Cookies): void => {
  Object.keys(cookies).forEach((key) => {
    const cookie = cookies[key];
    saveCookie(ctx, cookie);
  });
};

export const deleteCookie = (ctx: NextPageContext | undefined, key: string): void => {
  if (ctx && ctx.req) {
    nookies.destroy(ctx, key, { path: "/" });
  } else {
    //destroyCookie ?
    setCookie(null, key, "", {
      maxAge: -1,
      path: "/",
    });
  }
};

export const deleteCookies = (ctx: NextPageContext | undefined, keys: string[]): void => {
  keys.forEach((key) => {
    deleteCookie(ctx, key);
  });
};
