import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import createSagaMiddleware from "redux-saga";

import { createLoggerMiddleware } from "./middleware/console-logger";
import { rootReducer } from "./redux/reducers";
import rootSaga from "./redux/sagas";
import { ReduxStore } from "./types";
import { apiMiddlewares } from "../api/services";
import { hasCookie } from "../utils/cookies";

export function createReduxStore(): ReduxStore {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      let midleswares = getDefaultMiddleware({
        // thunk: false,
        serializableCheck: false,
      })
        .concat(sagaMiddleware)
        .concat(...apiMiddlewares);
      if (hasCookie(undefined, "_debug")) {
        // @ts-ignore-next-line
        midleswares.push(createLoggerMiddleware());
      }
      return midleswares;
    },
    devTools: false,
  });

  (store as ReduxStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store as ReduxStore;
}
export const storeWrapper = createWrapper(createReduxStore, { debug: false });
