import { FC, PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";
import { NextIntlClientProvider } from "next-intl";
import { settingsSelectors } from "@/core/store/redux/settings/selectors";
import { maskAllKeys } from "@/core/utils/intl";
import { hasCookie } from "@/core/utils/cookies";
import sourceMessages from "../../../intl/app/ro.json";

const AppTranslations: FC<PropsWithChildren> = ({ children }) => {
  const timezone = useSelector(settingsSelectors.timezone);

  const messages = useMemo(() => {
    if (hasCookie(undefined, "__intl_masked")) {
      maskAllKeys(sourceMessages, "***");
    }
    return sourceMessages;
  }, []);

  return (
    <NextIntlClientProvider locale={"ro"} timeZone={timezone || "Europe/Bucharest"} messages={messages}>
      {children}
    </NextIntlClientProvider>
  );
};

export default AppTranslations;
