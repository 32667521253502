import { createSelector } from "@reduxjs/toolkit";
import { ReduxState } from "@/store/types";

import { getURLParams } from "@/core/utils/urls";
import { RouterSegment } from "./types";

const url = (state: ReduxState): string | undefined => {
  return state.router.url;
};

const urlParams = createSelector(url, (url) => {
  return getURLParams(url);
});

const segment = (state: ReduxState): RouterSegment | undefined => {
  return state.router.segment;
};

export const routerSelectors = {
  url,
  urlParams,
  segment,
};
