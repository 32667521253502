import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuery(),
  tagTypes: ["RegistrationProviderReferralInfo"],
  endpoints: (builder) => ({
    loginCheckMethods: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/customers/auth/login/check-methods`,
        method: "POST",
        body: data,
      }),
    }),
    loginWithPassword: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/customers/auth/login/with-password`,
        method: "POST",
        body: data,
      }),
    }),
    loginSendEmailLink: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/customers/auth/login/send-email-link`,
        method: "POST",
        body: data,
      }),
    }),
    loginWithCode: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/customers/auth/login/with-code`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/customers/auth/register`,
        method: "POST",
        body: data,
      }),
    }),
    oauthInit: builder.mutation({
      query: ({ provider }) => ({
        url: `/api/customers/auth/oauth/init`,
        method: "POST",
        body: { provider },
      }),
    }),
    oauthHandle: builder.mutation({
      query: (data) => ({
        url: `/api/customers/auth/oauth/handle`,
        method: "POST",
        body: data,
      }),
    }),
    forgotPasswordInit: builder.mutation({
      query: (data) => ({
        url: `/api/customers/auth/password/reset/init`,
        method: "POST",
        body: data,
      }),
    }),
    forgotPasswordHandle: builder.mutation({
      query: (data) => ({
        url: `/api/customers/auth/password/reset/change`,
        method: "POST",
        body: data,
      }),
    }),
    confirmEmailResend: builder.mutation<any, void>({
      query: () => ({
        url: `/api/customers/auth/confirm-email/resend`,
        method: "POST",
      }),
    }),
    confirmEmail: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/customers/auth/confirm-email`,
        method: "POST",
        body: data,
      }),
    }),
    confirmEmailChange: builder.mutation<any, any>({
      query: (data) => ({
        url: `/api/customers/auth/confirm-email/change`,
        method: "POST",
        body: data,
      }),
    }),
    registrationProviderReferralInfo: builder.query({
      query: ({ code }) => ({
        url: `/api/providers-profiles/public/search/by-referral/${code}`,
        method: "GET",
      }),
      providesTags: ["RegistrationProviderReferralInfo"],
    }),
  }),
});
export const {
  useLoginWithPasswordMutation,
  useLoginCheckMethodsMutation,
  useLoginSendEmailLinkMutation,
  useLoginWithCodeMutation,
  useRegisterMutation,
  useOauthInitMutation,
  useOauthHandleMutation,
  useForgotPasswordInitMutation,
  useForgotPasswordHandleMutation,
  useConfirmEmailResendMutation,
  useConfirmEmailMutation,
  useConfirmEmailChangeMutation,
  useRegistrationProviderReferralInfoQuery,
} = authApi;
