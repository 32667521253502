export interface TrackingState {
  config: TrackingConfig;
  utm_params?: TrackingUTMParams;
  referral?: ReferralInfo;
}

export interface TrackingUTMParams {
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
}

export interface TrackingGA {
  measurementId: string;
}

export interface TrackingGTM {
  gtmId: string;
}

export enum ReferralInfoType {
  PROVIDER = "provider",
}

export interface ReferralInfo {
  type: ReferralInfoType;
  code: string;
}

export interface TrackingConfig {
  googleAnalytics?: TrackingGA;
  googleTagManager?: TrackingGTM;
  dynamicScripts?: string[];
}
