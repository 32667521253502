import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import { Cookie, CookieKey, deleteCookie, saveCookie } from "@/core/utils/cookies";
import { isClient } from "@/core/utils/nextjs";

import { contextActions } from "./actions";

function* onSetCookieOnClient(action: PayloadAction<Cookie>): Generator {
  saveCookie(undefined, action.payload);
}

function* onDeleteCookieOnClient(action: PayloadAction<CookieKey>): Generator {
  const key = action.payload;
  deleteCookie(undefined, key);
}

export default function* sagas(): Generator {
  if (isClient()) {
    yield takeLatest(contextActions.setCookie, onSetCookieOnClient);
    yield takeLatest(contextActions.deleteCookie, onDeleteCookieOnClient);
  }
}
