import { isClient } from "./utils/nextjs";

export const AUTH_USER_COOKIE_KEY = "__token";
export const AUTH_GUEST_COOKIE_KEY = "__gutoken";
export const REFERRAL_PROVIDER_COOKIE_KEY = "__preferral";
export const REFERRAL_PROVIDER_URL_KEY = "preferral";

export const TRACKING_UTM_SOURCE_COOKIE_KEY = "__utm_source";
export const TRACKING_UTM_MEDIUM_COOKIE_KEY = "__utm_medium";
export const TRACKING_UTM_CAMPAIGN_COOKIE_KEY = "__utm_campaign";

export const LANGUAGE_CHOICE_DEFAULT = "en";
export const LANGUAGE_CHOICES = [
  {
    value: "ro",
    label: "Română",
  },
];

export const GENDER_CHOICES = [
  {
    value: "",
    label: "Gen",
  },
  {
    value: "female",
    label: "Femeie",
  },
  {
    value: "male",
    label: "Bărbat",
  },
  {
    value: "other",
    label: "Alte",
  },
];

type TimezoneInfo = {
  value: string;
  label: string;
  searchValues?: string;
  frequent?: boolean;
};

export const TIMEZONE_CHOICES: TimezoneInfo[] = [
  {
    value: "Europe/Bucharest",
    label: "România",
    searchValues: "Romania Bucharest Bucuresti",
    frequent: true,
  },
  {
    value: "Europe/Rome",
    label: "Italia",
    searchValues: "Italia Rome Roma",
    frequent: true,
  },
  {
    value: "Europe/Madrid",
    label: "Spania",
    searchValues: "Spania Madrid Espana",
    frequent: true,
  },
  {
    value: "Europe/London",
    label: "Regatul Unit",
    searchValues: "United Kingdom London England Great Britain Anglia",
    frequent: true,
  },
  {
    value: "Europe/Berlin",
    label: "Germania",
    searchValues: "Deutschland Berlin Germany Germania",
    frequent: true,
  },
  {
    value: "Europe/Paris",
    label: "Franţa",
    searchValues: "Paris Franţa French Franceză",
    frequent: true,
  },
  {
    value: "Europe/Amsterdam",
    label: "Olanda",
    searchValues: "Netherlands Amsterdam Holland Olanda",
  },
  {
    value: "Europe/Brussels",
    label: "Belgia",
    searchValues: "Belgium Brussels Belgia",
  },
  {
    value: "Europe/Vienna",
    label: "Austria",
    searchValues: "Austria Vienna Viena",
  },
  {
    value: "Europe/Zurich",
    label: "Elveţia",
    searchValues: "Switzerland Zurich Elveţia",
  },
  {
    value: "Europe/Stockholm",
    label: "Suedia",
    searchValues: "Sweden Stockholm Suedia",
  },
  {
    value: "Europe/Oslo",
    label: "Norvegia",
    searchValues: "Norway Oslo Norvegia",
  },
  {
    value: "Europe/Copenhagen",
    label: "Danemarca",
    searchValues: "Denmark Copenhagen Danemarca Copenhaga",
  },
];

export const EXTENDED_TIMEZONE_CHOICES: TimezoneInfo[] = [
  {
    value: "Europe/Andorra",
    label: "Andorra",
    searchValues: "Andorra",
  },
  {
    value: "Europe/Tirane",
    label: "Albania",
    searchValues: "Albania Tirane",
  },
  {
    value: "Europe/Yerevan",
    label: "Armenia",
    searchValues: "Armenia Yerevan",
  },
  {
    value: "Europe/Minsk",
    label: "Belarus",
    searchValues: "Belarus Minsk",
  },
  {
    value: "Europe/Sofia",
    label: "Bulgaria",
    searchValues: "Bulgaria Sofia",
  },
  {
    value: "Europe/Zagreb",
    label: "Croatia",
    searchValues: "Croatia Zagreb",
  },
  {
    value: "Europe/Nicosia",
    label: "Cyprus",
    searchValues: "Cyprus Cipru Nicosia",
  },
  {
    value: "Europe/Prague",
    label: "Czech Republic",
    searchValues: "Czech Republic Prague",
  },
  {
    value: "Europe/Helsinki",
    label: "Finland",
    searchValues: "Finland Helsinki",
  },
  {
    value: "Europe/Athens",
    label: "Greece",
    searchValues: "Greece Athens",
  },
  {
    value: "Europe/Budapest",
    label: "Hungary",
    searchValues: "Hungary Ungaria Budapest",
  },
  {
    value: "Europe/Dublin",
    label: "Ireland",
    searchValues: "Ireland Dublin",
  },
  {
    value: "Europe/Valletta",
    label: "Malta",
    searchValues: "Malta Valletta",
  },
  {
    value: "Europe/Chisinau",
    label: "Moldova",
    searchValues: "Moldova Chisinau",
  },
  {
    value: "Europe/Warsaw",
    label: "Poland",
    searchValues: "Poland Warsaw",
  },
  {
    value: "Europe/Lisbon",
    label: "Portugal",
    searchValues: "Portugal Lisbon",
  },
  {
    value: "Europe/Belgrade",
    label: "Serbia",
    searchValues: "Serbia Belgrade",
  },
  {
    value: "Europe/Bratislava",
    label: "Slovakia",
    searchValues: "Slovakia Bratislava",
  },
  {
    value: "Europe/Ljubljana",
    label: "Slovenia",
    searchValues: "Slovenia Ljubljana",
  },
  {
    value: "Europe/Istanbul",
    label: "Turkey",
    searchValues: "Turkey Istanbul",
  },
  {
    value: "Europe/Kiev",
    label: "Ukraine",
    searchValues: "Ukraine Kiev",
  },
];

export const ALL_AVAILABLE_TIMEZONES_CHOICES: TimezoneInfo[] = [...TIMEZONE_CHOICES, ...EXTENDED_TIMEZONE_CHOICES];

if (isClient()) {
  const knownTimezones = new Set(ALL_AVAILABLE_TIMEZONES_CHOICES.map((t) => t.value));
  try {
    const timezones = window.Intl.supportedValuesOf("timeZone");
    for (const timezone of timezones) {
      if (!knownTimezones.has(timezone)) {
        ALL_AVAILABLE_TIMEZONES_CHOICES.push({
          value: timezone,
          label: timezone,
          searchValues: timezone,
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export const PROVIDER_RATING_CHOICES = [
  {
    value: "1",
    label: "Dezamăgit",
  },
  {
    value: "2",
    label: "Nesatisfăcător",
  },
  {
    value: "3",
    label: "Mediu",
  },
  {
    value: "4",
    label: "Bun",
  },
  {
    value: "5",
    label: "Excelent",
  },
];

export const PROVIDER_BOOKING_RATING_CHOICES = [
  {
    value: "1",
    label: "Dezamăgit",
  },
  {
    value: "2",
    label: "Nesatisfăcător",
  },
  {
    value: "3",
    label: "Mediu",
  },
  {
    value: "4",
    label: "Bun",
  },
  {
    value: "5",
    label: "Excelent",
  },
];
