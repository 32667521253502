import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReferralInfo, TrackingConfig, TrackingState, TrackingUTMParams } from "./types";

const getInitialState = (): TrackingState => {
  return {
    config: {
      googleAnalytics: undefined,
      googleTagManager: undefined,
      dynamicScripts: undefined,
    },
    utm_params: undefined,
    referral: undefined,
  };
};

export const trackingReducerSlice = createSlice({
  name: "tracking",
  initialState: getInitialState(),
  reducers: {
    setConfig(state, action: PayloadAction<TrackingConfig>) {
      state.config = action.payload;
    },
    setUTMParams(state, action: PayloadAction<TrackingUTMParams>) {
      state.utm_params = action.payload;
    },
    setReferral(state, action: PayloadAction<ReferralInfo>) {
      state.referral = action.payload;
    },
  },
});
